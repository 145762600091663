import React, { useEffect, useState } from "react";
import { Footer, NavBar, Login } from "../../components";
import headerImg from "../../assets/worship.png";
import sing from "../../assets/sing.png";
import td from "../../assets/tdjakes.jpeg";
import choir from "../../assets/mm_innerbg2-2.jpg";
import love from "../../assets/benefits3.jpg";
import banner from "../../assets/mm_bannerbarphoto-1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom';

const initialFormErrors = {
  email: "",
  memberID: "",
  auth: "",
};




const AboutView = () => {
  const [loginPopup, setLoginPopup] = useState(false);
    let [showInfo1, setShowInfo1] = useState(false);
    const [navBarOpen, setNavBarOpen] = useState(false);
    const [errors, setErrors] = useState(initialFormErrors);
    const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loginSuc = () => {
    // navigate('/echurch');
    navigate('/');
};
const unBlur = () => {
  setShowInfo1(false);
  setLoginPopup(false);
};

  const LoginButton = () => {
    errors.memberID = '';
    errors.email = '';
    setNavBarOpen(false);
    setShowInfo1(true);
    setLoginPopup(true);
};
  return (
    <div>
      <NavBar onLogin={LoginButton} navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen}/>
      <div className="header_about">
        <img className="about_img" src={choir} />

        {/* <h1>ABOUT</h1> */}
      </div>
      {!loginPopup ? (
                null
            ) : (
                <Login onClick={loginSuc} errors={errors} setErrors={setErrors} initialFormErrors={initialFormErrors} show={showInfo1} onClickOutside={unBlur} />
            )}
      <div
        className="section2"
        style={{
          display: "flex",
          // marginTop: "5%",
          height: 1000,
          //   borderWidth: 3,
          //         borderColor: "red",
          //         borderStyle: "solid",
        }}
      >
        <div
          className="pic_section"
          style={{
            width: "70%",
            position: "relative",
            marginTop: "5%",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
          }}
        >
          <img
            style={{ width: "70%" }}
            src="https://www.beckgroup.com/wp-content/uploads/2014/09/RE_PottersHouse_Ext_03.jpg"
          />
          <img
            style={{
              position: "absolute",
              width: "35%",
              height: "50%",
              bottom: 0,
              left: "35%",
            }}
            src="https://www.tdjakes.org/wp-content/uploads/2019/12/tdj-christmas-production.jpg"
          />
          <img
            style={{
              width: "30%",
              position: "absolute",
              right: "3%",
              top: "20%",
            }}
            src="https://www.thepottershouse.org/wp-content/uploads/BishopFL-scaled.jpg"
          />
        </div>
        <div
          style={{
            width: "35%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
          }}
          className="text_about"
        >
          <h3
            style={{
              fontSize: "4rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            WHO WE ARE
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
              Founded in 1996, The Potter's House is non-denominational,
              multicultural church led by Senior Pastor, T.D. Jakes. With more
              than 30,000 members on its rolls, The Potter's House is
              consistently ranked among the largest and most influential
              churches in the U.S.
              <br />
              <br /> The Potter's House operates as a non-profit organization
              with its principal campus in southern Dallas. Accelerated growth
              and shifting demographics have necessitated expansion to
              additional sites in North Dallas (Frisco, Texas,) Fort Worth,
              Texas and Denver.
              <br />
              <br /> Deeply rooted in the local and global communities, the
              Potter's House charter is to extend a “hand of help to the needy,
              a heart of compassion to the hurting, and a message of empowerment
              to the disenfranchised. “
            </p>
          </div>
          <img
            className="respone_img"
            style={{
              display: "none",
            }}
            src="https://www.tdjakes.org/wp-content/uploads/2019/12/tdj-christmas-production.jpg"
          />
          <h3
            className="believe"
            style={{
              fontSize: "4rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            WHAT WE BELIEVE
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
              There is one God, Creator of all things, infinitely perfect, and
              eternally existing in three manifestations: Father, Son and Holy
              Spirit. <br />
              <br />
              Jesus Christ is true God and true man, having been conceived of
              the Holy Spirit and born of the Virgin Mary. He died on the cross,
              the complete and final sacrifice for our sins according to the
              Scriptures. Further, He arose bodily from the dead, ascended into
              heaven, where, at the right hand of the Majesty on High, He is now
              our High Priest and Advocate.
            </p>
          </div>
        </div>
      </div>
      <div
        className="section3"
        style={{ display: "flex", marginTop: "5%", height: 1000 }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: "2%",
          }}
          className="text_about"
        >
          <h3 style={{ fontSize: "4rem", color: "#305C5B" }}>OUR MISSION</h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
            }}
          >
            <p>
              Our vision at The Potter's House (TPH) is for God to mold you into
              a purpose-filled vessel, pointing all grace and glory back to Him.
              <br />
              <br />
              It's our prayer that His Spirit will fully illuminate you on this
              heaven-bound journey, through every trial and victory. And as He
              empowers you to persevere through life's struggles, may you
              confidently communicate God's vibrant hope to others — shining His
              eternal light on the lost.
            </p>
          </div>
          <h3
            className="believe"
            style={{
              fontSize: "4rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            OUR CORE VALUES
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
              The shed blood of Jesus Christ and His resurrection provide the
              only ground for justification and salvation for all who believe,
              and only such as receive Jesus Christ by faith are born of the
              Holy Spirit and thus become children of God. The Return of Jesus
              Christ - The personal, pre-millennial, and imminent return of our
              Lord Jesus Christ is our hope and has a vital bearing on the
              personal life and service of the believer.
            </p>
          </div>
          <h3
            className="believe"
            style={{
              fontSize: "4rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            FOLLOW US
          </h3>
          <div class="social-container">
            <a
              href="https://www.youtube.com/channel/UCjQbTcszB-gRhDByY9WhySw"
              className="youtube social"
            >
              <FontAwesomeIcon icon={faYoutube} size="3x" />
            </a>
            <a
              href="https://www.facebook.com/bishopjakes/"
              className="facebook social"
            >
              <FontAwesomeIcon icon={faFacebook} size="3x" />
            </a>
            <a
              href="https://twitter.com/BishopJakes?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              className="twitter social"
            >
              <FontAwesomeIcon icon={faTwitter} size="3x" />
            </a>
            <a
              href="https://www.instagram.com/bishopjakes/?hl=en"
              className="instagram social"
            >
              <FontAwesomeIcon icon={faInstagram} size="3x" />
            </a>
          </div>
        </div>
        <div
          className="pic_section"
          style={{
            width: "70%",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
            position: "relative",
          }}
        >
          <img
            style={{ width: "50%", position: "absolute" }}
            src="https://www.thepottershouse.org/wp-content/uploads/July-2021-TPH-Blog_Images-25th-AnnFeature-Image-e1626890117242.jpg"
          />
          <img
            style={{
              width: "50%",
              position: "absolute",
              left: "30%",
              top: "20%",
            }}
            src={headerImg}
          />
          <img
            style={{
              width: "30%",
              position: "absolute",
              top: "45%",
              left: "17%",
            }}
            src={sing}
          />

          <img
            style={{
              width: "50%",
              position: "absolute",
              bottom: 0,
              right: "20%",
            }}
            src="https://vinepulse.com/wp-content/uploads/2020/04/Lakewood-church.jpg"
          />
          <img
            style={{
              width: "30%",
              position: "absolute",
              right: 20,
              bottom: "7%",
            }}
            src={love}
          />
        </div>
      </div>
      <div className="section2" style={{ display: "flex", marginTop: "5%" }}>
        <div
          className="pic_section"
          style={{
            display: "flex",

            justifyContent: "center",
            width: "70%",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
          }}
        >
          <img style={{ width: "50%", marginLeft: "35%" }} src={td} />
        </div>
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          className="text_about"
        >
          <h3 style={{ fontSize: "4rem", color: "#305C5B" }}>LEAD PASTOR</h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
            }}
          >
            <p>
              Bishop T. D. Jakes is a charismatic leader, visionary, provocative
              thinker, and entrepreneur who serves as senior pastor of The
              Potter's House, a global humanitarian organization and
              30,000-member church located in Dallas, Texas. T.D. Jakes' mission
              is carried out through four distinct channels: global humanitarian
              outreach, inspirational conference series, media and entertainment
              ventures, and thought leadership forums. <br />
              <br />
              T.D. Jakes has advised the last three presidents, was a featured
              speaker at the inauguration of Barack Obama in 2009, gave remarks
              at a White House Easter Prayer Breakfast in 2011, attended the
              state dinner for African leaders in 2014 and Pope Francis' 2015
              White House visit.
            </p>
          </div>
          <div class="social-container">
            <a
              href="https://www.facebook.com/bishopjakes/"
              className="facebook social"
            >
              <FontAwesomeIcon icon={faFacebook} size="3x" />
            </a>
            <a
              href="https://twitter.com/BishopJakes?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              className="twitter social"
            >
              <FontAwesomeIcon icon={faTwitter} size="3x" />
            </a>
            <a
              href="https://www.instagram.com/bishopjakes/?hl=en"
              className="instagram social"
            >
              <FontAwesomeIcon icon={faInstagram} size="3x" />
            </a>
          </div>
        </div>
      </div>
      <div
        className="section2"
        style={{ display: "flex", marginTop: "5%", marginBottom: "5%" }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: "3%",
          }}
          className="text_about"
          id="response"
        >
          <h3 style={{ fontSize: "4rem", color: "#305C5B" }}>BENEFITS</h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",

              fontSize: "1.3rem",
            }}
          >
            <p>
              Welcome to The Potter's House of Dallas church family, where
              everyone has a seat at the table. As a valuable church member,
              you're plugging into a community of bold believers. That means no
              matter where you're tuning in, we experience God's presence as one
              vessel.
              <br />
              <br />
              Every week, we bring you the best streaming experience possible so
              you get the most out of every message. Services stream live weekly
              at 9 a.m. Sundays and 7 p.m. Wednesdays. Times reflect Central
              Daylight Time (CDT).
            </p>
          </div>
        </div>
        <div
          className="pic_section"
          id="bottom_sec"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "70%",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
          }}
        >
          <img className="bottom_pic" style={{ width: "50%" }} src={banner} />
        </div>
      </div>
      <Footer class={"about_footer"} />
    </div>
  );
};

export default AboutView;
