import React, { useEffect} from 'react';
import {BiCheckCircle} from "react-icons/bi";
import { useNavigate } from "react-router-dom";




const SubmissionThanks = () => {
    const navigate = useNavigate();

    // useEffect(() => {
        
    // },[])

    const GoHome = () => {
        document.body.style.overflow = 'visible';
        navigate('/')
    };
    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `linear-gradient(
            45deg,
            hsl(266deg 52% 29%) 0%,
            hsl(236deg 39% 39%) 43%,
            hsl(218deg 51% 41%) 57%,
            hsl(208deg 54% 44%) 65%,
            hsl(203deg 46% 49%) 71%,
            hsl(198deg 43% 56%) 75%,
            hsl(193deg 40% 64%) 78%,
            hsl(187deg 36% 72%) 81%,
            hsl(178deg 32% 82%) 85%,
            hsl(167deg 39% 93%) 100%
          )`, backgroundSize: 'cover'}}>
        <div className='thanku_container' style={{ backgroundColor: '#E6F4F1', height: '50vh', width: '45%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', borderRadius: '1vh', boxShadow: '5px 10px rgba(136, 136, 136, .3)'}}>
            <BiCheckCircle  color='#15abf6' fontSize={100} style={{marginTop: '-10%'}}/>
            <h1>THANK YOU FOR YOUR VIDEO SUBMISSION!</h1>
            <h3>WE WILL BE IN TOUCH.</h3>
            <button
            onClick={GoHome} style={{ fontSize: "1.3rem",
            borderRadius: "1vh",
            width: "16vw",
            height: "4vh",
            marginTop: '3%',
            borderStyle: 'none',
            backgroundColor: '#15abf6',
            }}>Go Home</button>
        </div>
        </div>
    );
};

export default SubmissionThanks;


// background-image: `linear-gradient(
//     45deg,
//     hsl(266deg 52% 29%) 0%,
//     hsl(236deg 39% 39%) 43%,
//     hsl(218deg 51% 41%) 57%,
//     hsl(208deg 54% 44%) 65%,
//     hsl(203deg 46% 49%) 71%,
//     hsl(198deg 43% 56%) 75%,
//     hsl(193deg 40% 64%) 78%,
//     hsl(187deg 36% 72%) 81%,
//     hsl(178deg 32% 82%) 85%,
//     hsl(167deg 39% 93%) 100%
//   )`