import React from "react";
import { TailSpin } from "react-loading-icons";
import axios from "axios";
const Form = (props) => {
  const {
    change,
    submit,
    errors,
    setErrors,
    initialFormErrors,
    loading,
    setLoading,
    forgotMember,
    setForgotMember,
    emailSent,
    setEmailSent,
    cred,
    setCred,
  } = props;
  const { email, memberID } = props.values;

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newVal = value;
    change(name, newVal);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, value, type, checked } = e.target;
    const newVal = value;
    change(name, newVal);
    submit();
  };
  const SubmitEmail = (e) => {
    if (cred.email.length === 0) {
      errors.email = "Email is required";
    } else {
      setLoading(true);
    axios
      .post(
        "https://payment.tdjakes.org/api/member/forgotdonornumber",
        { email: cred.email },
        {
          headers: {
            Authorization:
              "Bearer " +
              "XMHpyT6vhkgAteKKlfvoctsMaybHjvfZQOl6t96aM5HGoKtr9XdectgxyiCMIe5",
          },
        }
      )
      .then((res) => {
        if (res.data.memberfound) {
          setLoading(false);
          setEmailSent(true);
          setErrors(initialFormErrors);
        } else {
          setLoading(false);
          setErrors({ ...errors, email: "Member not Found, Please try again" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  const goBack = () => {
    setForgotMember(false);
    setEmailSent(false);
    setCred({ email: "", memberID: "" });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="loginContainer">
        <div className="smallLogo"></div>
        <div className="loginSection">
          {forgotMember ? (
            emailSent ? (
              <>
                <p style={{display: 'flex', textAlign: 'center', padding: 20}}>Thank you a email has been sent with your member number</p>
                <button
                  onClick={goBack}
                  className="login_button"
                  style={{
                    fontSize: "1.2rem",
                    borderRadius: "1vh",
                    marginTop: "3%",
                    display: 'flex',
                    flexWrap: 'wrap',
                    height: 'auto',
                    justifyContent: 'center',
                    textAlign: 'center',
                    borderStyle: "none",
                    backgroundColor: "#15abf6",
                  }}
                >
                  {"Go Back to Login"}
                </button>{" "}
              </>
            ) : (
              <>
                <h3>Forgot Member Number</h3>{" "}
                <p style={{ color: "red" }}>{errors.email}</p>
                <p>Enter Email</p>{" "}
                <label htmlFor="email">
                  {" "}
                  Email
                  <input
                    onChange={onChange}
                    name="email"
                    type="email"
                    value={email}
                  />
                </label>{" "}
                <button
                  onClick={SubmitEmail}
                  className="login_button"
                  style={{
                    fontSize: "1.2rem",
                    borderRadius: "1vh",
                    width: "16vw",
                    height: "5vh",
                    marginTop: "3%",
                    borderStyle: "none",
                    backgroundColor: "#15abf6",
                  }}
                >
                  {loading ? <TailSpin /> : "Submit"}
                </button>
              </>
            )
          ) : (
            <>
              <h3 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>LOGIN</h3>
              <p style={{ color: "red" }}>{errors.email}</p>
              <p style={{ color: "red" }} className="member_error">
                {errors.memberID}
              </p>

              <label htmlFor="email">
                {" "}
                Email
                <input
                  onChange={onChange}
                  name="email"
                  type="email"
                  value={email}
                />
              </label>
              <label htmlFor="memberID">
                {" "}
                Member ID
                <input
                  onChange={onChange}
                  name="memberID"
                  type="text"
                  value={memberID}
                />
              </label>
              <button
                className="login_button"
                style={{
                  fontSize: "1.2rem",
                  borderRadius: "1vh",
                  width: "16vw",
                  height: "5vh",
                  marginTop: "3%",
                  borderStyle: "none",
                  backgroundColor: "#15abf6",
                  cursor: 'pointer'
                }}
              >
                {loading ? <TailSpin /> : "LOGIN"}
              </button>
              <p>
                Not a Member? Become a Member{" "}
                <a target="_blank" href="https://forms.thepottershouse.org/become-a-member/">
                  Here!
                </a>
              </p>
              <p style={{ marginTop: "-1%" }}>
                Forgot your Member ID?{" "}
                <a href="#" onClick={() => setForgotMember(true)}>
                  Click Here!
                </a>
              </p>
            </>
          )}
        </div>
        <div className="banner">
          <div
            className="banner-img"
            style={{
              backgroundImage:
                'url("https://entrepreneurhandbook.co.uk/wp-content/uploads/2018/03/Example-of-vector-abstract-image.png.webp")',
            }}
          ></div>
        </div>
      </div>
    </form>
  );
};

export default Form;
