import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom';
// import styles from '../styles/FooterComp.css';
const Footer = (props) => {
  const navigate = useNavigate();

  const aboutClick = () => {
    console.log('about');
    navigate('/echurch/about');
};
const HomeClick = () => {
    console.log('Home');
    navigate('/');
};
const EchurchClick = () => {
    console.log('echurch');
    navigate("/echurch/echurch/about");
};
const EchoirClick = () => {
    console.log('echoir');
    navigate("/echurch/echoir/about");
};
  return (
    <div className={props.class || "footer"}>
      <div className="container" style={{ width: "100%" }}>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="col" style={{ marginLeft: "10%" }}>
            <h3>USEFUL LINKS</h3>
            <ul
              className="list-unstyled"
              style={{ lineHeight: "180%", listStyle: "none" }}
            >
              <li style={{cursor: 'pointer'}}onClick={HomeClick}>HOME</li>
              <li style={{cursor: 'pointer'}} onClick={aboutClick}>ABOUT</li>
              <li style={{cursor: 'pointer'}} onClick={EchurchClick}>ECHURCH</li>
              <li style={{cursor: 'pointer'}} onClick={EchoirClick}>ECHOIR</li>
              <li style={{cursor: 'pointer'}} onClick={(e) => {
                    e.preventDefault();
                    window.open('https://payments.tdjakes.org/give/?utm_source=Display&utm_medium=Web&utm_campaign=2020giving&utm_content=https://www.thepottershouse.org/', '_blank');
                }}>DONATE</li>
            </ul>
          </div>
          <div className="col" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href="https://www.thepottershouse.org" target="_blank"><img src="https://www.thepottershouse.org/wp-content/themes/tph-custom/images/logo-grayscale-v4.png" style={{height: "18vh"}}/></a>
          </div>
          <div className="col" style={{ textAlign: "center"}}>
            <h3>FOLLOW US</h3>
            <div class="social" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%'}}>
              <a
                href="https://www.youtube.com/channel/UCjQbTcszB-gRhDByY9WhySw"
                className="youtube social"
              >
                <FontAwesomeIcon icon={faYoutube} size="3x" />
              </a>
              <a
                href="https://www.facebook.com/bishopjakes/"
                className="facebook social"
              >
                <FontAwesomeIcon icon={faFacebook} size="3x" />
              </a>
              <a
                href="https://twitter.com/BishopJakes?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                className="twitter social"
              >
                <FontAwesomeIcon icon={faTwitter} size="3x" />
              </a>
              <a
                href="https://www.instagram.com/bishopjakes/?hl=en"
                className="instagram social"
              >
                <FontAwesomeIcon style={{color: 'white'}} icon={faInstagram} size="3x" />
              </a>
            </div>
            <div>
            </div>
          </div>
          <div className="col" style={{ marginRight: "10%", textAlign: "left" }}>
            <h3 style={{textAlign: 'center'}}>CONTACT US</h3>
            <ul
              className="list-unstyled"
              style={{ listStyle: "none", lineHeight: "150%", display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', width: '100%' }}
            >
              <li>6777 W. KEIST BLVD</li>
              <li>DALLAS, TEXAS 75236</li>
              <li>1-800-247-4672</li>
              <li>MON-THUR: 9AM-4PM</li>
              <li>SUN: 9AM-12PM</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <p className="col-sm" style={{fontSize: 12}}>
            &copy;{new Date().getFullYear()} ECHURCH | ALL RIGHTS RESERVED |
            TERMS OF SERVICE | PRIVACY
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
