import React, { useEffect, useState } from "react";
import { Footer, NavBar, Login } from "../../components";
import benefits3 from "../../assets/benefits_include.jpg";
import sing from "../../assets/benefits4.jpeg";
import echurch from "../../assets/benefits2.jpg";
import welcome3 from "../../assets/welcome3.jpg";
import choir from "../../assets/echurch_1.jpg";
import welcome from "../../assets/welcome_2.JPG";
import welcome2 from "../../assets/welcome_1.jpeg";
import member1 from "../../assets/Become_a_member_1.JPG";
import member2 from "../../assets/Become_a_member_2.JPG";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom';

const initialFormErrors = {
  email: "",
  memberID: "",
  auth: "",
};

const EchurchView = () => {
  const [loginPopup, setLoginPopup] = useState(false);
    let [showInfo1, setShowInfo1] = useState(false);
    const [navBarOpen, setNavBarOpen] = useState(false);
    const [errors, setErrors] = useState(initialFormErrors);
    const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loginSuc = () => {
    // navigate('/echurch');
    navigate('/');
};
const unBlur = () => {
  setShowInfo1(false);
  setLoginPopup(false);
};

  const LoginButton = () => {
    errors.memberID = '';
    errors.email = '';
    setNavBarOpen(false);
    setShowInfo1(true);
    setLoginPopup(true);
};
  return (
    <div>
      <NavBar onLogin={LoginButton} navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen}/>
      <div className="header_about">
        <img className="about_img" src={choir} />

        
      </div>
      <div
        className="section2"
        style={{
          display: "flex",
          // marginTop: "5%",
          height: 1000,
          //   borderWidth: 3,
          //         borderColor: "red",
          //         borderStyle: "solid",
        }}
      >
        {!loginPopup ? (
                null
            ) : (
              <Login onClick={loginSuc} errors={errors} setErrors={setErrors} initialFormErrors={initialFormErrors} show={showInfo1} onClickOutside={unBlur} />
            )}
        <div
          className="pic_section"
          style={{
            width: "70%",
            position: "relative",
            marginTop: "5%",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
          }}
        >
          <img
            style={{ width: "70%" }}
            src={welcome}
          />
          <img
            style={{
              position: "absolute",
              width: "35%",
              height: "55%",
              bottom: 0,
              left: "35%",
            }}
            src={welcome2}
          />
          <img
            style={{
              width: "30%",
              position: "absolute",
              right: "3%",
              top: "20%",
            }}
            src={welcome3}
          />
        </div>
        <div
          style={{
            width: "35%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
          }}
          className="text_about"
        >
          <h3
            style={{
              fontSize: "3.5rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            WELCOME TO OUR ONLINE SANCTUARY
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
              Our eChurch offers a spiritual oasis for those unable to attend
              services in person. Here, you can immerse yourself in a
              transformative experience by streaming on your desktop, mobile
              devices, or through the TDJ Ministries app.
            </p>
          </div>
          <h3
            className="believe"
            style={{
              fontSize: "3.5rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            OUR MISSION
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
              The purpose of The Potter's House eChurch ministry is to provide a
              virtual space for our members to Belong, Bond, and Build TOGETHER!
            </p>
          </div>
          <h3
            className="believe"
            style={{
              fontSize: "3.5rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            LIVE STREAM WEEKLY SERVICES
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <ul>
              <a href="http://tdjakes.org/watchnow">Watch now</a>
              <li>Sundays at 9 a.m.</li>
              <li>Wednesdays at 7 p.m.</li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="section3"
        style={{ display: "flex", marginTop: "5%", marginBottom: '5%' }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: "2%",
          }}
          className="text_about"
        >
          <h3 style={{ fontSize: "3.5rem", color: "#305C5B" }}>
            BECOME AN E-MEMBER
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
            }}
          >
            <p>
              If you are between church homes and find value in our content, we
              invite you to join our eChurch!
            </p>
          </div>
          <h3
            style={{
              fontSize: "3.5rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            BENEFITS INCLUDE:
          </h3>
          <div
            className="text_words"
            style={{
              marginTop: "-8%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <ul>
              <li>Exclusive eMember updates and giveaways</li>
              <li>
                {" "}
                Connection with thousands of other Potter’s House eMembers
              </li>
              <li>
                {" "}
                Weekly inspiration through The Potter’s House live-streaming
                network
              </li>
              <li>
                {" "}
                Fellowship with devoted believers who will encourage you on your
                life’s journey
              </li>
              <li>
                The power of faith knows no physical boundaries. Increase your
                spiritual health, right here in our vibrant community.
              </li>
            </ul>
          </div>
          <h3
            style={{
              fontSize: "3.5rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            FOLLOW US
          </h3>
          <div class="social-container">
            <a
              href="https://www.youtube.com/channel/UCjQbTcszB-gRhDByY9WhySw"
              className="youtube social"
            >
              <FontAwesomeIcon icon={faYoutube} size="3x" />
            </a>
            <a
              href="https://www.facebook.com/bishopjakes/"
              className="facebook social"
            >
              <FontAwesomeIcon icon={faFacebook} size="3x" />
            </a>
            <a
              href="https://twitter.com/BishopJakes?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              className="twitter social"
            >
              <FontAwesomeIcon icon={faTwitter} size="3x" />
            </a>
            <a
              href="https://www.instagram.com/bishopjakes/?hl=en"
              className="instagram social"
            >
              <FontAwesomeIcon icon={faInstagram} size="3x" />
            </a>
          </div>
        </div>
        <div
          className="pic_section"
          style={{
            width: "70%",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
            position: "relative",
          }}
        >
          <img
            style={{ width: "40%", position: "absolute", border: '2px solid #555' }}
            src={member1}
          />
          <img
            style={{
              width: "50%",
              position: "absolute",
              left: "30%",
              top: "20%",
              border: '2px solid #555'
            }}
            src={member2}
          />
          <img
            style={{
              width: "30%",
              position: "absolute",
              top: "35%",
              left: "10%",
            }}
            src={sing}
          />

          <img
            style={{
              width: "30%",
              position: "absolute",
              bottom: '13%',
              right: "30%",
            }}
            src={echurch}
          />
          <img
            style={{
              width: "30%",
              position: "absolute",
              right: 30,
              bottom: "10%",
            }}
            src={benefits3}
          />
        </div>
      </div>

      <Footer class={"about_footer"} />
    </div>
  );
};

export default EchurchView;
