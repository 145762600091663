import React, { useState } from "react";
import { Footer, NavBar } from "../../components";
import { useNavigate } from "react-router-dom";
import VideoInput from "./VideoInput";
import sing from "../../assets/pexels-dana-tentis-370799.jpg";
import internet from "../../assets/nmheadbanner4.jpg";
import worship from "../../assets/worship.png";

const ServicesView = () => {
  const navigate = useNavigate();

  const ReadyToServe = () => {
    console.log("serve button");
  };

  return (
    <div className="instrucBody">
      <NavBar />
      <div
        style={{
          width: "100%",
          height: "28vh",
          backgroundColor: "#462472",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginTop: "6%", color: "white", fontSize: "3.5rem" }}>
          WELCOME TO ECHURCH SERVICES
        </h1>
      </div>
      <div
      className="video_sec"
        style={{
          width: "100%",
          height: "65vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#E6F4F1",
        }}
      >
        <p style={{ margin: "2%", fontWeight: "bold" }}>
          **TO LEARN MORE WATCH THIS SHORT INSTRUCTIONAL VIDEO**
        </p>
        <iframe
        className="vid"
          autoplay={true}
          style={{ width: "80vw", height: 600 }}
          src="https://player.vimeo.com/video/330128824"
          frameBorder="0"
          allow="autoplay"
          allowFullScreen
          title="video"
        />
      </div>
      <div
      className="instruc_instruc"
        style={{
          backgroundColor: "#E6F4F1",
          // height: "80vh",
          paddingBottom: '80px',
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
        className="instru-pic"
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5%",
            position: "relative",
          }}
        >
          <img
            src={sing}
            style={{
              height: "40%",
              width: "45%",
              position: "absolute",
              top: "18%",
              left: "19%",
            }}
          />

          <img
            src={worship}
            style={{
              height: "40%",
              width: "55%",
              position: "absolute",
              bottom: "3%",
              left: "10%",
            }}
          />
          <img
            src={internet}
            style={{
              height: "35%",
              width: "45%",
              position: "absolute",
              right: "8%",
              top: "30%",
            }}
          />
        </div>

        <div className="list">
          <h2
            style={{
              marginTop: "8%",
              fontSize: "3.5rem",
              textAlign: "left",
              color: "#462472",
            }}
          >
            VOLUNTEER INFORMATION
          </h2>
          <p
            className="service_body"
            style={{ width: "50vw", fontSize: "1.5rem", marginVertical: "5%" }}
          >
            Ready to serve? We have opportunities for you to serve right where
            you live! No matter your city, state, or country, we would love to
            get you plugged into our community.
            <br />
            <br /> There are four ways to volunteer with The Potter’s House eChurch remotely.
          </p>
          <ul>
          <li style={{fontSize: 24}}>eChoir – singing with other eMembers as well as our local choir members</li>
          <li style={{fontSize: 24}}>eDance – dancing with other eMembers as well as our local dance team</li>
          <li style={{fontSize: 24}}>eGreeter – greeting TPH members on Sunday mornings and introducing the preshow</li>
          <li style={{fontSize: 24}}>eChurch Brand Ambassador – engaging with eMembers and other viewers while</li>
          </ul>
          <img
          className="res_picture"
            src={internet}
            style={{
              display: 'none'
            }}
          />
        </div>
      </div>
      <div
      className="foot"
        style={{
          backgroundColor: "#462472",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "white", fontSize: "3.5rem" }}>
          READY TO GET STARTED?
        </h2>
      </div>
    </div>
  );
};

export default ServicesView;

