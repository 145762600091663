import React, { useState, useEffect } from "react";
import echurch from "../assets/icon_echurch.jpg";
import logo from "../assets/TPH-echurch-logo.png";
import { NavBarMenu } from "../components";
import { useNavigate } from "react-router-dom";

const NavBar = (props) => {
  let [showInfo2, setShowInfo2] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const unBlur = () => {
    setShowInfo2(false);
    props.setNavBarOpen(false);
  };
  const navBarClick = () => {
    props.setNavBarOpen(true);
  };
  const aboutClick = () => {
    console.log("about");
    navigate("/echurch/about");
  };
  const HomeClick = () => {
    console.log("Home");
    navigate("/");
  };
  const EchurchClick = () => {
    console.log("echurch");
    navigate("/echurch/echurch/about");
  };
  const EchoirClick = () => {
    console.log("echoir");
    navigate("/echurch/echoir/about");
  };
  const DonateClick = () => {
    window.open(
      "https://payments.tdjakes.org/give/?utm_source=Display&utm_medium=Web&utm_campaign=2020giving&utm_content=https://www.thepottershouse.org/",
      "_blank"
    );

   
  };

  const logoutFunction = () => {
    localStorage.removeItem("token");
    navigate('/')
    window.location.reload(true)
    
};

  return (
    <div className={"navbar"}>
      <div className="headLogo">
        <img src={logo} alt="echurch" />
      </div>
      <div className="nav">
        {/* <div className="nav_click">
          <h4 onClick={HomeClick}>Home</h4>
        </div> */}
        {/* <div className="nav_click" id="about">
          <h4 onClick={aboutClick}>About</h4>
        </div>
        <div className="nav_click" id="echurch">
          <h4 onClick={EchurchClick}>Echurch</h4>
        </div>
        <div className="nav_click" id="echoir">
          <h4 onClick={EchoirClick}>Echoir</h4>
        </div>
        <div className="nav_click">
          <h4 onClick={DonateClick}>Donate</h4>
        </div> */}
      </div>
      <div onClick={navBarClick} className="menuContainer">
        <div className="menu" />
        <div className="menu" />
        <div className="menu" />
      </div>
      {!isMobile && localStorage.getItem("token") !== null? (
        <div style={{position: 'relative', right: '-15%', marginTop: '.6%'}} className="nav_click">
          <h4 style={{cursor: 'pointer'}} onClick={logoutFunction}>Logout</h4>
        </div>
      ) : !props.loginPopup && props.navBarOpen ? (
        <NavBarMenu
          onLogin={props.onLogin}
          onHome={HomeClick}
          onAbout={aboutClick}
          onEchurch={EchurchClick}
          onEchoir={EchoirClick}
          show={showInfo2}
          onClickOutside={unBlur}
        />
      ) : null}
    </div>
  );
};

export default NavBar;
