import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title, Login, SundaySection, BecomeMember, Footer, NavBar } from './components';
import background from './assets/choir.jpeg';

const initialFormErrors = {
  email: "",
  memberID: "",
  auth: "",
};

function App() {
    const [loginPopup, setLoginPopup] = useState(false);
    let [showInfo1, setShowInfo1] = useState(false);
    const [navBarOpen, setNavBarOpen] = useState(false);
    const [errors, setErrors] = useState(initialFormErrors);
    // const events = [
    //     "load",
    //     "mousemove",
    //     "mousedown",
    //     "click",
    //     "scroll",
    //     "keypress",
    //   ];
      // let timer;
    // useEffect(()=>{
    //     window.scrollTo(0,0);
    //   },[]);

      // const handleLogoutTimer = () => {
      //   timer = setTimeout(() => {
      //     // clears any pending timer.
      //     resetTimer();
      //     // Listener clean up. Removes the existing event listener from the window
      //     Object.values(events).forEach((item) => {
      //       window.removeEventListener(item, resetTimer);
      //     });
      //     // logs out user
      //     logoutAction();
      //   }, 150000); // 10000ms = 10secs. You can change the time.
      // };

      // const resetTimer = () => {
      //   if (timer) clearTimeout(timer);
      // };

      // useEffect(() => {
      //   Object.values(events).forEach((item) => {
      //     window.addEventListener(item, () => {
      //       resetTimer();
      //       handleLogoutTimer();
      //     });
      //   });
      // }, []);

      const logoutAction = () => {
        localStorage.clear();
        window.location.pathname = "/";
      };

    const navigate = useNavigate();

    const LoginButton = () => {
      errors.memberID = '';
      errors.email = '';
        setNavBarOpen(false);
        setShowInfo1(true);
        setLoginPopup(true);
    };
    const unBlur = () => {
        setShowInfo1(false);
        setLoginPopup(false);
    };
    const loginSuc = () => {
        navigate('/echurch');
    };
    const echoir= () => {
        navigate('/echurch');
    };

    const echurch = () => {
        navigate("/echurch/volunteers")
    }

    return (
        <div className="body" style={{backgroundColor: '#CBC3E3'}}>
            {/* <div className="header" style={loginPopup ? { filter: 'blur(8px)' } : null}>
                <NavBar onLogin={LoginButton} navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen}/>
                <img src={background} alt={'Webpage logo'} />
            </div> */}

         
 {/* {!loginPopup ? (
                <Title echurch={echurch} echoir={echoir} onClick={LoginButton} />
            ) : (
                <Login onClick={loginSuc} errors={errors} setErrors={setErrors} initialFormErrors={initialFormErrors}  show={showInfo1} onClickOutside={unBlur} />
            )}
            {/* <SundaySection loginPopup={loginPopup} />
            <BecomeMember loginPopup={loginPopup} /> */}



<Login onClick={loginSuc} errors={errors} setErrors={setErrors} initialFormErrors={initialFormErrors}  />

            {/* <Footer /> */}
        </div>
    );
}

export default App;
