import React, { useEffect, useState} from 'react';
import { Footer, NavBar, Login } from "../../components";
import headerImg from "../../assets/worship.png";
import sing from "../../assets/echurch12.jpg";
import td from "../../assets/echurch_4.jpeg";
import choir from '../../assets/echoir_page1.jpg';
import love from '../../assets/pic1.JPG';
import banner from "../../assets/pic2.JPG";
import { useNavigate } from 'react-router-dom';
const initialFormErrors = {
  email: "",
  memberID: "",
  auth: "",
};

const EchoirView = () => {
  const [loginPopup, setLoginPopup] = useState(false);
  let [showInfo1, setShowInfo1] = useState(false);
  const [navBarOpen, setNavBarOpen] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState(initialFormErrors);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])


  const loginSuc = () => {
    // navigate('/echurch');
    navigate('/');
};
const unBlur = () => {
  setShowInfo1(false);
  setLoginPopup(false);
};

  const LoginButton = () => {
    errors.memberID = '';
    errors.email = '';
    setNavBarOpen(false);
    setShowInfo1(true);
    setLoginPopup(true);
};
  return (
    <div>
      <NavBar onLogin={LoginButton} navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen}/>
      <div className="header_about">
        <img className="about_img" src={choir} />

      
      </div>
      {!loginPopup ? (
                null
            ) : (
                <Login onClick={loginSuc} errors={errors} setErrors={setErrors} initialFormErrors={initialFormErrors} show={showInfo1} onClickOutside={unBlur} />
            )}
      <div
        className="section2"
        style={{
          display: "flex",
        }}
      >
        <div
          className="pic_section"
          style={{
            width: "70%",
            position: "relative",
            marginTop: "5%",
          }}
        >
          <img
            style={{ width: "70%" }}
            src={love}
          />
          <img
            style={{
              position: "absolute",
              width: "30%",
              height: "50%",
              bottom: 0,
              left: "40%",
            }}
            src={sing}
          />
          <img
            style={{
              width: "30%",
              position: "absolute",
              right: "3%",
              top: "20%",
            }}
            src={td}
          />
        </div>
        <div
          style={{
            width: "35%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // borderWidth: 3,
            // borderColor: "red",
            // borderStyle: "solid",
          }}
          className="text_about"
        >
          <h3
            style={{
              fontSize: "4rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            WHAT IS ECHOIR
          </h3>
          <div
          className='text_words'
            style={{
              marginTop: "-12%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
             Through the
miracle of modern technology, The Potter’s House has created a virtual ensemble that will allow
eMembers worldwide to sing for their church on Sunday mornings from the comfort of their
homes.
            </p>
          </div>
          <h3
          className='believe'
            style={{
              fontSize: "4rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            PURPOSE
          </h3>
          <div
          className='text_words'
            style={{
              marginTop: "-12%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
            The purpose of The Potter’s House eChurch ministry is to provide a virtual space for our
members to Belong, Bond, and Build TOGETHER!
            </p>
          </div>
          <h3
          className='believe'
            style={{
              fontSize: "4rem",
              color: "#305C5B",
              //    borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            AUDITION FOR ECHOIR
          </h3>
          <div
          className='text_words'
            style={{
              marginTop: "-12%",
              paddingRight: "10%",
              fontSize: "1.3rem",
              // borderWidth: 3,
              //     borderColor: "red",
              //     borderStyle: "solid",
            }}
          >
            <p>
            Are you an eMember blessed with the gift of song? If so, the eChoir wants YOU! If you want to join the eChoir, please <span style={{color: 'blue', cursor: 'pointer'}} onClick={LoginButton}>login,</span> and click on eChoir to sign up today!
            </p>
          </div>
        </div>
      </div>
      
      <div className="section2" style={{ display: "flex", marginTop: "5%", marginBottom: '5%' }}>
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: '3%',
          }}
          className="text_about"
        >
          <h3 style={{ fontSize: "4rem", color: "#305C5B" }}>GET INVOLVED</h3>
          <div  className='text_words' style={{ marginTop: "-8%",
              
              fontSize: "1.3rem", }}>
                <p>There are four ways to volunteer with The Potter’s House eChurch remotely.</p>
            <ul>
            
            <li style={{fontSize: 24}}>eChoir – singing with other eMembers as well as our local choir members</li>
          <li style={{fontSize: 24}}>eDance – dancing with other eMembers as well as our local dance team</li>
          <li style={{fontSize: 24}}>eGreeter – greeting TPH members on Sunday mornings and introducing the preshow</li>
          <li style={{fontSize: 24}}>eChurch Brand Ambassador – engaging with eMembers and other viewers while</li>
            </ul>
          </div>
        </div>
        <div
          className="pic_section"
          id='bottom_sec'
          style={{
            display: 'flex', 
            justifyContent: 'center',
            width: "70%",
          }}
        >
          <img
          className='bottom_pic'
            style={{ width: "70%" }}
            src={banner}
          />
        
        </div>
      </div>
      <Footer class={'about_footer'}/>
    </div>
  );
};

export default EchoirView;
