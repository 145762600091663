import React, { useState } from "react";
import { Footer, NavBar } from "../../components";
import { useNavigate } from "react-router-dom";
import VideoInput from "./VideoInput";
import sing from "../../assets/echoir_tonya.jpg";
import band from "../../assets/join_pham.jpg";
import worship from "../../assets/echoir_onstage.jpg";
const InstructionComp = () => {
  const [uploadingVideo, setUploadingVideo] = useState(false);

  const ReadyToSubmit = () => {
    setUploadingVideo(true);
  };

  if (uploadingVideo) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "visible";
  }
  const Video = "https://www.youtube.com/watch?v=Kl4okICup5U";
  const navigate = useNavigate();

  const echoir = () => {
    navigate("/echurch/echoir");
  };

  const service = () => {
    navigate("/echurch/service");
  };
  return (
    <div className="instrucBody">
      <NavBar />
      <div
        className="title_up"
        style={{
          width: "100%",
          height: "28vh",
          backgroundColor: "#462472",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginTop: "6%", color: "white", fontSize: "3.5rem" }}>
          WELCOME TO ECHOIR AUDITIONS
        </h1>
      </div>
      <div
        className="video_sec"
        style={{
          width: "100%",
          height: "65vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#E6F4F1",
        }}
      >
        <p style={{ margin: "2%", fontWeight: "bold" }}>
          **TO LEARN MORE WATCH THIS SHORT INSTRUCTIONAL VIDEO**
        </p>
        <iframe
          className="vid"
          autoplay={true}
          style={{ width: "80vw", height: 600 }}
          src="https://player.vimeo.com/video/330128824"
          frameBorder="0"
          allow="autoplay"
          allowFullScreen
          title="video"
        />
      </div>
      <div className="instruc_instruc"
        style={{
          backgroundColor: "#E6F4F1",
          // height: "80vh",
          minHeight: '700px',
          paddingBottom: '80px',
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
        className="instru-pic"
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5%",
            position: "relative",
          }}
        >
          <img
            src={sing}
            style={{
              height: "40%",
              width: "40%",
              position: "absolute",
              top: "16%",
              left: "12%",
            }}
          />
          <img
            src={band}
            style={{
              height: "40%",
              width: "40%",
              position: "absolute",
              right: "14%",
              top: "24%",
            }}
          />

          <img
            src={worship}
            style={{
              height: "40%",
              width: "55%",
              position: "absolute",
              bottom: "8%",
              left: "18%",
            }}
          />
          
        </div>

        <div className="list">
          <h2
            style={{
              marginTop: "8%",
              fontSize: "3.5rem",
              textAlign: "left",
              color: "#462472",
            }}
          >
            VIDEO UPLOAD INSTRUCTIONS
          </h2>
          <ol
            className="instruction_body"
            style={{ width: "50vw", fontSize: "1.5rem", marginVertical: "5%" }}
          >
            <li>
              Video can be shot with the front or back lense of your device or
              from a computer laptop.
            </li>
            <li> Video should be shot from the waist up.</li>
            <li>Use solid color backgrounds with no images or logos. </li>
            <li>
              Click the “Submit your entry” button to go to the upload page.
            </li>
            <li>Upload your audition.</li>
            <li>Click the "Submit Video" button and we will notfiy you when we review it!</li>
          </ol>
          <img
            className="res_picture"
            src={worship}
            style={{
              display: 'none'
            }}
          />
        </div>
      </div>
      <div
      className="foot"
        style={{
          backgroundColor: "#462472",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ color: "white", fontSize: "3.5rem" }}>
          READY TO GET STARTED?
        </h2>
        <button className="submitVideo" onClick={ReadyToSubmit}>
        Submit your entry!
        </button>
      </div>

      {uploadingVideo ? <div className="blurFilter"></div> : null}

      {uploadingVideo ? (
        <VideoInput
          uploadingVideo={() => setUploadingVideo(false)}
          width={400}
          height={300}
        />
      ) : null}
    </div>
  );
};

export default InstructionComp;
