import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiUpload2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loading-icons';
import * as tus from 'tus-js-client';
import axios from 'axios';


export default function VideoInput(props) {
  const VIMEO_ACCESS_TOKEN = `ca52af6d8094fb24e2aba53db87c5651`

  


  const navigate = useNavigate();
  const { width, height } = props;

  const inputRef = React.useRef();
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [size, setSize] = useState(0);


  const [source, setSource] = React.useState();

  const body = {
    upload: {
      approach: 'tus',
      size,
    },
    name: `Echoir-${localStorage.getItem('firstName')}-${localStorage.getItem('lastName')}-${fileName}`,
    privacy: {
      view: 'anybody',
      embed: 'public',
    },
    embed: {
      color: '#4338CA',
    },
  };
  const headers = {
    Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
    'Content-Type': 'application/json',
    Accept: 'application/vnd.vimeo.*+json;version=3.4',
  };
  




  const submitVideo = () => {
    setUploadingVideo(true);
    axios.post('https://api.vimeo.com/me/videos', body, { headers }).then((res) => {
      
    const upload = new tus.Upload(file, {
      endPoint: 'https://api.vimeo.com/me/videos',
      uploadUrl: res.data.upload.upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: fileName,
        filetype: file.type
      },
      headers: {},
      onError: function(error) {
        console.log('Failed because: ' + error);
      },
      onProgress: function(bytesUploaded, bytesTotal) {
        let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + '%');
      },
      onSuccess: function() {
        console.log('Download %s from %s', upload.file.name, upload.url);

         navigate("/echurch/echoir/submission");
         setUploadingVideo(false);
        setVideoUrl(upload.url);
      }
    });
    upload.start();
    
    }).catch((err) => {
      console.log(err)
    })
    // await timeout(3000);
    // navigate("/echurch/echoir/submission")
    
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSize(file.size);
    setFile(file)
    setFileName(file.name)
    setSource(url);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };
  return (
    <div className="upload_section">
      <div className="upload_title">
        <h3>Upload Video</h3>
        <div className="upload_header_icon">
          <AiOutlineClose onClick={props.uploadingVideo} />{" "}
        </div>
      </div>
      {!source ? (
        <div className="upload_icon">
          <RiUpload2Fill color={"grey"} />
        </div>
      ) : null}

      {!source && (
        <>
          <div className=" upload_words">
            <p
              style={{
                fontWeight: "bold",
                marginTop: "20%",
                fontSize: "1.5rem",
              }}
            >
              Select files to upload
              <br />{" "}
              <p style={{ fontWeight: "normal" }}>
                Or drag and drop video files
              </p>
            </p>
          </div>
          <input
            style={{ fontSize: "1rem" }}
            ref={inputRef}
            className="VideoInput_input"
            type="file"
            onChange={handleFileChange}
            accept=".mov,.mp4"
          />{" "}
        </>

        // background: "transparent",
        // borderColor: "white",
        // borderWidth: 2,
        // borderRadius: "1vh",
        // borderStyle: "solid",
        // width: "16vw",
        // height: "5vh",
        // fontSize: "1.4em",
        // marginBottom: '5%',
      )}
      {!source && (
        <button
          style={{
            
            fontSize: "1.3rem",
            borderRadius: "1vh",
            width: "16vw",
            height: "5vh",
            borderStyle: 'none',
            backgroundColor: '#15abf6',
          }}
          onClick={handleChoose}
        >
          Select Files
        </button>
      )}
      {source && (
        <video
        style={{marginTop: '5%'}}
          className="VideoInput_video"
          width="100%"
          height={height}
          controls
          src={source}
        />
      )}
      <div className="VideoInput_footer" style={{fontSize: '1.5rem', marginTop: '1%', marginBottom: '1%'}}>{source || "Nothing selected"}</div>

      {source ? (
        <>
          <button  style={{
            fontSize: "1.3rem",
            borderRadius: "1vh",
            width: "16vw",
            height: "5vh",
            marginTop: '3%',
            borderStyle: 'none',
            backgroundColor: '#15abf6',
          }}onClick={submitVideo}>
            {uploadingVideo ? <TailSpin/> : 'Submit Video'}
          </button>{" "}
          <button  style={{
            fontSize: "1.3rem",
            borderRadius: "1vh",
            width: "16vw",
            height: "5vh",
            marginTop: '2%',
            borderStyle: 'none',
            backgroundColor: '#15abf6',
          }}onClick={() => setSource()}>Try Again</button>
        </>
      ) : null}
    </div>
  );
}
