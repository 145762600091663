import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NavBarMenu = (props) => {
    const navigate = useNavigate();
    const ref = useRef(null);
    const { onClickOutside } = props;

    const logout = () => {
        localStorage.removeItem("token");
        navigate('/')
        window.location.reload(true)
        
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <div style={{ color: 'white' }} ref={ref} onClick={props.onClick} className="nav_menu">
            <div className="nav_clicks" style={{ margin: '10%' }}>
                <h3 onClick={props.onHome}>Home</h3>
                <h3 onClick={props.onAbout}>About</h3>
                <h3 onClick={props.onEchurch}>Echurch</h3>
                <h3 onClick={props.onEchoir}>Echoir</h3>
                <h3 onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'https://payments.tdjakes.org/give/?utm_source=Display&utm_medium=Web&utm_campaign=2020giving&utm_content=https://www.thepottershouse.org/';
                }}>Donate</h3>
                {localStorage.getItem('token') ? <h3 onClick={logout}>Logout</h3> : <h3 onClick={props.onLogin}>Login</h3>}
                
            </div>
        </div>
    );
};

export default NavBarMenu;
