import * as yup from 'yup';

const formSchema = yup.object().shape({
   email: yup
        .string()
        .email("Must be an valid email")
        .required("email is required"),
    memberID: yup
        .string()
        .required("Member ID is required")
})

export default formSchema;