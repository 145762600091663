import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/styles/App.css";
import "../src/styles/NavBar.css";
import "../src/styles/TitleSection.css";
import "../src/styles/LoginForm.css";
import "../src/styles/SundaySection.css";
import "../src/styles/MemberSection.css";
import "../src/styles/FooterComp.css";
import "../src/styles/AboutPage.css";
import "../src/styles/UploadPage.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  Instructions,
  UploadVideo,
  Services,
  About,
  Submission,
  Volunteers,
  Echoir,
  Echurch
} from "./components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Fragment>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/echurch/about" element={<About />} />
          <Route path="/echurch/echoir/about" element={<Echoir />} />
          <Route path="/echurch/echurch/about" element={<Echurch />} />

          <Route element={<PrivateRoutes />}>
            <Route element={<Instructions />} path="/echurch" exact />
            <Route path="/echurch/echoir" exact element={<UploadVideo />} />
            <Route path="/echurch/volunteers" exact element={<Volunteers />} />
            {/* <Route path="/echurch/service" element={<Services />} /> */}
            <Route path="/echurch/echoir/submission" element={<Submission />} />
          </Route>
        </Routes>
      </Fragment>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
