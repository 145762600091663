import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import formSchema from "../components/Validation/formSchema";
import * as yup from "yup";
import Form from "./Form";

const initialFormValues = {
  email: "",
  memberID: "",
};


const LoginComp = (props) => {
  let location = useLocation();
  const navigate = useNavigate();
  const { onClickOutside, errors, setErrors, initialFormErrors } = props;
  const [cred, setCred] = useState(initialFormValues);
  // const [errors, setErrors] = useState(initialFormErrors);
  const [loading, setLoading] = useState(false);
  const [forgotMember, setForgotMember] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const validate = (name, value) => {
    yup
      .reach(formSchema, name)
      .validate(value)
      .then(() => setErrors({ ...errors, [name]: "" }))
      .catch((err) => setErrors({ ...errors, [name]: err.errors[0] }));
  };
  const handleChange = (name, value) => {
    validate(name, value);
    setCred({ ...cred, [name]: value });
  };
  const handleSubmit = (name, value) => {
    if (cred.email.length === 0) {
      errors.email = "Email is required";
    } else if (cred.memberID.length === 0) {
      errors.memberID = "Member ID is required";
    } else {
      setLoading(true);
      axios
        .post("https://payment.tdjakes.org/api/member/lookup", cred, {
          headers: {
            Authorization:
              "Bearer " +
              "XMHpyT6vhkgAteKKlfvoctsMaybHjvfZQOl6t96aM5HGoKtr9XdectgxyiCMIe5",
          },
        })
        .then((res) => {
          if (res.data.correctlogin === true) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("firstName", res.data.firstname)
            localStorage.setItem("lastName", res.data.lastname)
            localStorage.setItem("email", cred.email);
              navigate("/echurch");
            setLoading(false);
          } else {
            setErrors({ ...errors, email: "Login Failed, try again" });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       onClickOutside && onClickOutside();
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, [onClickOutside]);

  // if (!props.show) return null;

  return (
    <div>
      <Form
        values={cred}
        emailSent={emailSent}
        setLoading={setLoading}
        cred={cred}
        setCred={setCred}
        setEmailSent={setEmailSent}
        forgotMember={forgotMember}
        setForgotMember={setForgotMember}
        loading={loading}
        change={handleChange}
        initialFormErrors={initialFormErrors}
        errors={errors}
        setErrors={setErrors}
        submit={handleSubmit}
      />
    </div>
  );
};

export default LoginComp;
