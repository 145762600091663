import React, { useState } from "react";
import { Footer, NavBar } from "../../components";
import { RiUpload2Fill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import VideoInput from "./VideoInput";
const UploadVideoComp = () => {
  const [uploadingVideo, setUploadingVideo] = useState(false);

  const ReadyToSubmit = () => {
    setUploadingVideo(true);
  };

  if (uploadingVideo) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "visible";
  }
  return (
    <div className="submission">
      <NavBar />
      <div style={{ textAlign: "center" }}>
        <h1>Echoir Video Submission</h1>
        <div>
          <h2>Video Upload Instuctions</h2>
        </div>
        <ol className="instruction_body">
          <li>
            Video can be shot with the front or back lense of your device or
            from a computer laptop.
          </li>
          <li> Video should be shot from the waist up.</li>
          <li>Use solid color backgrounds with no images or logos. </li>
          <li>
            Click the “Submit your entry” button to go to the upload page.
          </li>
          <li>
            Use the code “tph” (all lower case) to access the upload form.{" "}
          </li>
          <li>
            *Further help can be found on the video upload page by clicking the
            help icon. If you have any trouble uploading your video, please
            email us at echurch@tdjakes.org{" "}
          </li>
        </ol>
        <button onClick={ReadyToSubmit}>Ready to Submit?</button>
      </div>
      {uploadingVideo ? <div className="blurFilter"></div> : null}

      {uploadingVideo ? (
        <VideoInput
          uploadingVideo={() => setUploadingVideo(false)}
          width={400}
          height={300}
        />
      ) : null}

      <Footer />
    </div>
  );
};

export default UploadVideoComp;
